import React from "react"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Logo from '../components/Logo'
import Menu from '../components/Menu'
import Title from '../components/Title'
import QuizzForm from '../components/QuizzForm'
import DogsHeader from '../components/DogsHeader'
import SEO from '../components/SEO'

export default function Quizz() {
  const breakpoints = useBreakpoint();

  return (
    <main>
      <SEO
        title="O meu deu Pinscher, e o seu? Que cachorro você seria?"
        description="Responda o quizz e saiba que tipo de cachorro você seria no site da Urdog."
        image="/img/pinscher.jpg"
      />
      {/* <Ads/> */}
      <Logo/>
      <Menu/>
      <header className={(!breakpoints.md) ? 'bigger-font' : ''}>
        <Title>
          Que cachorro você seria?!
        </Title>
        <DogsHeader/>
      </header>
      <QuizzForm/>

      {/* RD Station */}
      <script type="text/javascript" async src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/e0bc903a-3a06-4880-a685-c4cfb6f1b5d2-loader.js" ></script> 
    </main>
  )
}